import React from "react";
import ReactDOM from "react-dom";
import i18next, { InitOptions } from "i18next";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import HttpApi, { BackendOptions } from "i18next-http-backend";
import LanguageDetector, {
  DetectorOptions,
} from "i18next-browser-languagedetector";
import { BreakpointProvider } from "react-socks";

const translationOptions: InitOptions = {
  fallbackLng: "en-XY",
  backend: {
    loadPath: "/translations/{{lng}}.json",
  } as BackendOptions,
  detection: {
    order: [
      "path",
      "subdomain",
      "querystring",
      "cookie",
      "localStorage",
      "sessionStorage",
      "navigator",
    ],
  } as DetectorOptions,
};

i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init(translationOptions, () =>
    ReactDOM.render(
      <React.StrictMode>
        <Router>
          <BreakpointProvider>
            <Route path="/:locale" component={App} />
          </BreakpointProvider>
        </Router>
      </React.StrictMode>,
      document.getElementById("root")
    )
  );
