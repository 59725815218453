/* eslint-disable react/style-prop-object */
import React from "react";
import i18next from "i18next";
import { Text } from "../components";

export const UnsubscribeSuccessPage: React.FC = () => {
  return (
    <div className="main-container unsubscribe-bg">
      <div className="sub-container">
        <Text>{i18next.t("unsubscribe.success.subheader")}</Text>
      </div>
    </div>
  );
};
