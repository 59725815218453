import React from "react";
import { css, cx } from 'emotion';
import { Color, Colors } from "../Variables";

interface ITextProps {
  color?: Color;
  inline?: boolean;
  style?: 'h1' | 'h2' | 'h3' | 'h4'
  align?: 'left' | 'right'| 'center' ;
  minWidth?: string;
  onClick?(): void;
}

export const Text: React.FC<ITextProps> = ({color, style, inline = false, children, onClick, align, minWidth}) => {
  const cssClass = cx(style, css`
    user-select: none;
    margin: 0;
    min-width: ${minWidth};
    text-align: ${align};
  `, color ? css`color: ${Colors[color]};` : undefined);

  return inline ? <span onClick={onClick} className={cssClass}>{children}</span> : <p onClick={onClick} className={cssClass}>{children}</p>
};
