import React from "react";
import { Route, useParams } from "react-router-dom";
import { UnsubscribePage, UnsubscribeSuccessPage } from "./pages";
import { AppWrapper } from "./components";

import "@ingka/core/dist/style.css";
import "@ingka/base/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/inline-text/dist/style.css";
import "@ingka/typography/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/image-info/dist/style.css";
import "@ingka/aspect-ratio-image/dist/style.css";
import "@ingka/toast/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/tabs/dist/style.css";

export const App: React.FC = () => {
  const { locale } = useParams<{ locale: string }>();

  const uid = new URLSearchParams(window.location.search).get("uid");
  const title = new URLSearchParams(window.location.search).get("title");
  const orderNo = new URLSearchParams(window.location.search).get("orderNo");

  return (
    <AppWrapper>
      <Route
        exact
        path="/:locale/unsubscribe"
        component={() => (
          <UnsubscribePage
            locale={locale}
            uid={uid}
            title={title}
            orderNo={orderNo}
          />
        )}
      />
      <Route
        exact
        path="/:locale/unsubscribe/success"
        component={() => <UnsubscribeSuccessPage />}
      />
    </AppWrapper>
  );
};
