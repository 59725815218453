import React from "react";
import { css, cx } from 'emotion';

interface IStackProps {
  vertical?: boolean;
  spacing?: number;
  alignItems?: 'center';
  justifyContent?: 'center';
  maxWidth?: string;
}

const verticalStyle = (margin: number) => css`
  flex-direction: column;
  & > * {
    margin-bottom: ${margin}px !important;
  }
  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;


const horizontalStyle = (margin: number) => css`
  flex-direction: row;
  & > * {
    margin-right: ${margin}px !important;
  }
  & > *:last-child {
    margin-right: 0 !important;
  }
`;

export const Stack: React.FC<IStackProps> = ({vertical, maxWidth, children, alignItems, justifyContent, spacing = 12}) => (
  <div className={cx(css`
    display: flex;
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    max-width: ${maxWidth};`, vertical ? verticalStyle(spacing) : horizontalStyle(spacing))}>
    {children}
  </div>
);
