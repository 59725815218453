import React from "react";
import { css } from "emotion";

const logoStyle = css`
  height: 48px;
  pointer-events: none;
  margin: 1rem;
`;

export const Header: React.FC = () => (
    <header>
      <img src={'/images/logo.svg'} className={logoStyle} alt="logo" />
    </header>
);
