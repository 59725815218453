import React from "react";
import { Header } from './Header.component';
import { Footer } from './Footer.component';

export const AppWrapper:React.FC = ({children}) => (
    <div>
      <Header />
          {children}
      <Footer />
    </div>
  )
