import React from "react";
import { css } from "emotion";
import { Text } from "../typography/Text.component";
import { Stack } from "../layout/Stack.component";
import i18next from "i18next";

const footerStyle = css`
  text-align: center;
  margin-top: 1.5rem;
`;

const logoStyle = css`
  height: 24px;
  pointer-events: none;
`;

export const Footer: React.FC = () => (
    <footer className={footerStyle}>
      <Stack vertical>
        <img src={'/images/logo.svg'} className={logoStyle} alt="logo" />
        <Stack justifyContent="center">
          <a href={i18next.t('footer.contactLink')}>{i18next.t('footer.contact')}</a>
          <a href={i18next.t('footer.privacyPolicyLink')}>{i18next.t('footer.privacyPolicy')}</a>
        </Stack>
        <Text color="neutralGrey300">© Inter IKEA Systems B.V. 2020</Text>
      </Stack>
    </footer>
);
