/* eslint-disable react/style-prop-object */
import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import i18next from "i18next";
import { Stack, Text } from "../components";
import Button from "@ingka/button";
import { useHistory } from "react-router-dom";

interface IUnsubscribePageProps {
  locale: string;
  uid: string | null;
  title: string | null;
  orderNo: string | null;
}

async function getIpAddress(props: any) {
  let clientIpAddress = null;
  const publicIp = require("public-ip");
  (async () => {
    try {
      clientIpAddress = await publicIp.v4();
      if (clientIpAddress == null) {
      } else {
        props(clientIpAddress);
      }
    } catch (e) {
      console.log("");
    }
  })();
}

console.log(process.env.NODE_ENV);

let API_URL: string;
let RECAPTCHA_SITE_KEY: string;

if (process.env.NODE_ENV === "development") {
  API_URL = "http://localhost:8081/";
  RECAPTCHA_SITE_KEY = "6Lf6FrgZAAAAAJVsfLLW_fNd5399SJgJ3aR2g2I_";
} else if (process.env.NODE_ENV === "production") {
  API_URL = "https://dev.salesassistant.ingka.com/api";
  RECAPTCHA_SITE_KEY = "6Lel4sMZAAAAAOP9PrAHzQvr7gjcajme8xhQwpAl";
} /*else if (process.env.NODE_ENV === "dev") {
  API_URL = 'https://dev.salesassistant.ingka.com';
  RECAPTCHA_SITE_KEY = '6Lel4sMZAAAAAOP9PrAHzQvr7gjcajme8xhQwpAl';
}*/

export const UnsubscribePage: React.FC<IUnsubscribePageProps> = ({
  locale,
  uid,
  title,
  orderNo,
}) => {
  const history = useHistory();
  const [reCaptcha, setReCaptcha] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reCatpchaRef = useRef<ReCAPTCHA>(null);
  let isValid = !!reCaptcha;
  const [clientIp, setClientIp] = useState("");
  getIpAddress(setClientIp);
  const [captchaExpiry, setCaptchaExpiry] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [responseData, setResponseData] = useState("");
  const onSubmit = () => {
    setIsLoading(true);
    setCaptchaExpiry("");
    fetch(`${API_URL}/delegate/optOut?ipAddress=${clientIp}&uid=${uid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        captchaResponse: reCaptcha,
      },
    })
      .then((results) => results.json())
      .then((data) => {
        setIsLoading(false);
        console.log("API response:: ", data, "error:: ", data.error);
        setIsSubmit(true);
        if (data === true) {
          setResponseData("success");
        } else {
          setResponseData("failure");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const setRecaptcha = (value: any) => {
    setReCaptcha(value);
    if (value === null) {
      setReCaptcha("");
      setCaptchaExpiry("expired");
      isValid = true;
    }
  };

  return (
    <div className="main-container unsubscribe-bg">
      <div className="sub-container">
        {responseData && responseData === "success" && isSubmit === true && (
          <>
            <Text>
              {i18next.t("unsubscribe.success.subheader")} {title}
            </Text>
          </>
        )}
        {captchaExpiry === "expired" && (
          <>
            <Text style="h2">
              {i18next.t("callback.expired.captchaHeadline")}
            </Text>
            <Text>{i18next.t("callback.expired.captchaTitle")}</Text>
            <a
              className="clickHereLink"
              href={`${history.location.pathname}${history.location.search}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  `${history.location.pathname}${history.location.search}`
                );
              }}
            >
              <Text>{i18next.t("callback.expired.tryAgain")}</Text>
            </a>
          </>
        )}
        {responseData && responseData === "failure" && isSubmit === true && (
          <>
            <Text>{i18next.t("error.generic")}</Text>
          </>
        )}
        {!isSubmit && captchaExpiry === "" && (
          <>
            <Text style="h2">{i18next.t("unsubscribe.headline")}</Text>
            <Text style="h4">
              {i18next.t("unsubscribe.subheader")}
              {orderNo === "No"
                ? i18next.t("unsubscribe.salesOpp")
                : i18next.t("unsubscribe.orderNum")}{" "}
              {title}
              {i18next.t("unsubscribe.subheader1")}
            </Text>
            <Text>{i18next.t("unsubscribe.subheader2")}</Text>
            <Stack vertical spacing={24}>
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={setRecaptcha}
                hl={locale}
                ref={reCatpchaRef}
              />
              <Button
                text={i18next.t("unsubscribe.submit")}
                type="emphasised"
                disabled={!isValid}
                onClick={onSubmit}
                loading={isLoading}
              />
            </Stack>
            <p className="unsubscribe-bottomText">
              {i18next.t("unsubscribe.subheader3")}
            </p>
            <p>{i18next.t("unsubscribe.subheader4")}</p>
          </>
        )}
      </div>
    </div>
  );
};
